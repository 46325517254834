import moment from 'moment';


export default class globalsHelpers{


    /**
     * 
     * Devuelve un string en formato moneda
     * 
     * @param {Number} value 
     * @param  {Number} decimals
     * @returns {String}
     */
    static formatCurrency = (value:Number,decimals:Number):String => {
     
      if(value===null || isNaN(value)){
        return '';
      }
      
      let response:String = value.toLocaleString("de-DE", {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });

      return `${response}€`;
    };

     /**
     * 
     * Devuelve un string en formato moneda
     * 
     * @param {Number} value 
     * @param  {Number} decimals
     * @returns {String}
     */
     static formatNumber = (value:Number,decimals:Number):String => {
     
      if(value===null || isNaN(value)){
        return '';
      }

      let response:String = value.toLocaleString("de-DE", {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      });

      return `${response}`;
    };




    /**
     * 
     * Devuelve una fecha en formato es
     * 
     * @param  {Date} date
     * @returns {String}
     */
    static formatDateLocale = date => {
      moment.locale('es');
      return moment(date).format('D MMM Y');
    };

  /**
     * 
     * Devuelve una fecha en formato es largo
     * 
     * @param  {Date} date
     * @returns {String}
     */
    static formatDateLocaleLong = date => {
        moment.locale('es');
        return moment(date).format('DD/MM/Y HH:mm')
    };



      /**
     * 
     * Devuelve una fecha en formato es largo
     * 
     * @param  {Date} date
     * @returns {String}
     */
      static formatDateStandard = date => {
        moment.locale('es');
        return moment(date).format('DD/MM/Y')
    };

     /**
     * 
     * Devuelve una fecha en formato es largo
     * 
     * @param  {Date} date
     * @returns {String}
     */
     static formatHour = date => {
      moment.locale('es');
      return moment(date).format('HH:MM')
  };


    /**
     * 
     * Convierte un string en slug
     * @param {String} str
     * @returns {String}
     */
    static getSlug = str =>
      str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');

  }

